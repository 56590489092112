import bankroller from 'assets/bankrollerImg.png'

export const ThirdProject = {
  id: 3,
  name: 'BankRoller',
  desc: 'An application for tracking daily arbitrage net gain/loss with an interactive calendar and performance insights.',
  longDesc: `I developed an arbitrage tracking web app using the MERN stack to help users seamlessly record and analyze their 
  daily net gains and losses. The backend, powered by Node.js, Express, and MongoDB, efficiently stores transaction data, 
  ensuring fast retrieval and scalability. On the frontend, I used React along with simple yet effective libraries like react-calendar 
  for an interactive calendar and notistack for smooth notifications, creating an intuitive user experience. 
  To enhance data visualization, I integrated Recharts, allowing users to track cumulative performance and identify trends over time. 
  Designed for usability and real-time insights, this application empowers arbitrage traders to optimize their strategies and maximize long-term profits.`,
  link: '',
  img: bankroller,
  technology: ['ReactJS', 'Typescript', 'ExpressJS', 'NodeJS', 'MongoDB'],
  year: 2025,
  date:'Jan 30, 2025',
  online: true,
}